import { lazy } from 'react';
import _ from 'lodash';

const importComponents = (pageComponents, componentConfigs) => {
  const appData = [];
  for (const property of pageComponents) {
    const Component = lazy(() => {
      let moduleName = undefined;
      _.forOwn(componentConfigs, function (value, key) {
        if (key === property) {
          moduleName = value?.module;
        }
      });
      return import(
        `../modules/${moduleName || property}/${moduleName || property}`
      ).catch((err) => {
        console.error(`123 Failed to load component: ${property}`, err);
        return import('../modules/LazyImportFallback/LazyImportFallback');
      });
    });
    appData.push({ Component, ComponentName: property });
  }
  return appData;
};

export default importComponents;
