const translationsEn = {
    "status": "success",
    "data": {
        "lang": "en_US",
        "app": "hotel",
        "translation": {
            "crud": {
                "updatedSuccessfully": "Успішно оновлено",
                "validationFailed" : "Перевірка не пройшла",
                "fieldRequired": "Обов'язкове поле",
                "operationError" : "Виникла помилка",
                "emptyList" : "Дані не знайдено",
                "failToLoad" : "Помилка завантаження",
                "modelNotFound" : "Не знайдено",
            },
            "updateCard": {
                "payment_failed_please_update_card": "Payment failed - please update card",
                "open_the_payment_form": "Open the payment form",
            },
            "upgradePlan": {
                "general": {
                    "upgrade_plan": "План оновлення",
                    "upgrade_plan_to_regular": "Оновити до звичайного плану",
                    "upgrade_plan_to_pro": "Оновити до плану PRO",
                  },
                "regular": {
                    "title": "Оновіть зараз і отримайте доступ до продуктів Agent 360!",
                    "benefits_title_text": "Ви наразі підключені до пакету Agent 360 Light, якщо ви оновите, ви зможете отримати",
                    "text_1": "Оптові продукти Sky 360",
                    "text_2": "Готелі для всіх напрямків по всьому світу",
                    "text_3": "Регулярні та бюджетні рейси",
                    "text_4": "Атракції по всьому світу",
                    "button_text": "Оновіть зараз до Agent 360!"
                },
                "pro": {
                    "title": "Оновіть зараз і отримайте вебсайт для вашого бізнесу з повним брендуванням!",
                    "benefits_title_text": "У пакеті SKY 360 Pro ви можете насолоджуватися",
                    "text_1": "Вебсайт з унікальним доменом",
                    "text_2": "Брендування бізнесу - логотип та кольори сайту",
                    "text_3": "Просування пакетів та рейсів на сайті за напрямками",
                    "text_4": "Усі зроблені бронювання будуть належати вашій агенції!",
                    "button_text": "Оновіть зараз до Pro!"
                }
            },
            "attractionQuestions": {
                "customerInfo": "Customer Info",
                "participantsInfo": "Participants Info",

                "first_name": "First Name",
                "last_name": "Last Name",
                "email_address": "Email Address",
                "phone_number": "Phone Number",
                "pickup_location": "Pickup Location",
                "height_in_centimeters": "Height in Centimeters",
                "weight_in_kilograms": "Weight in Kilograms",
            },
            "contactUs": {
                "email": "Електронна пошта",
                "phone": "Телефон",
                "fullName": "Повне ім'я",
                "writeUsMessage": "Напишіть нам повідомлення",
                "send": "Відправити",
                "phoneRequired": "Будь ласка, введіть номер телефону",
                "emailRequired": "Будь ласка, введіть адресу електронної пошти",
                "fullNameRequired": "Будь ласка, введіть повне ім'я",
                "messageRequired": "Будь ласка, введіть повідомлення",
                "aboutUs": "Про нас"
            },
            "cart": {
                "addToCart": "Додати до кошика",
            },
            "customerSupport": {
                "customerService": "Customer Service",
                "sendMessageAndGetNotified": "Send us a message and the auctioneer will get back to you as soon as possible",
                "callUs": "Call us: ",
            },
            "roomInfo": {
                "info": "Опис номера",
                "viewAllThePhotos": "Переглянути всі фотографії",
                "roomSize": "Розмір номера (кв. м)",
                "peopleInTheRoom": "Люди в номері",
                "doubleBed": "Двоспальне ліжко"
            },
            "conceptsList":{
                "conceptsListTitle": "Concepts List Title",
                "includesActivities": "Gift attractions",
                "includesActivity": "Includes a gift attraction",
                "includeInPackage": "Includes In Package",
                "transfer": "Transfer",
                "includesTransfer": "Включає трансфер",
                "transferContent": "HOS transfers at the price of the package",
                "baseBoard": "Base Board",
                "autoApprove": "Auto Approve",
                "airways": "Airways",
                "flightFrom": "Flight From",
                "flightBack": "Flight Back",
                "toOrder": "To Order",
                "selectFlight": "Select A Flight",
                "reselectFlight": "Reselect Flights",
                "flightSelectedSuccessfully": "Flight Selected Successfully",
                "nights": "Nights",
                "noPackagesFound": "No Package Found",
                "moreDeals":"אופציות נוספות",
                "pricePerPerson":"Price Per Person",
            },
            "viewFlight": {
                "show_tickets_with_different_fare_combinations":"Показати квитки з різними варіантами тарифів",
                "ticketFare":"Тариф",
                "ticketInfo":"Інформація про квиток",
                "termsAndConditions": "Правила та умови",
                "ifTicketIncludeSeat": "Якщо квиток включає місце - клієнту потрібно буде вибрати його на сайті авіакомпанії",
                "inCaseOfChange": "У разі зміни - клієнту потрібно буде доплатити, якщо оновлений квиток коштує дорожче, також стягується додаткова плата за сервіс згідно з Правилами та умовами сайту",
                "inCaseOfCancellation": "У разі скасування - буде додаткова плата за сервіс згідно з Правилами та умовами сайту",
                "error4305Title": "Ой, рейс повністю заброньований",
                "error4305Content": "Вибачте, але здається, що місця за цією пропозицією розпродані, і ми не можемо продовжити покупку. Ви будете перенаправлені на головну сторінку.",
                "TC": "ТК",
                "flights": "Рейси",
                "title": "Вибраний рейс, перевірте деталі та переходьте до оплати",
                "addBaggage": "Додати багаж",
                "closeAddBaggage": "Приховати опції",
                "addTrolly": "Додати тролей",
                "somethingWetWrong": "Щось пішло не так",
                "suitcasePrice": "Ціна валізи",
                "trollyPrice": "Ціна тролей",
                "totalPerPassenger": "Загальна сума на одного пасажира",
                "updatePassengers": "Оновити кількість пасажирів",
                "search": "Пошук",
                "seating": "Місця",
                "cancelation": "Скасування квитка",
                "meal": "Харчування",
                "trolly": "Тролей",
                "suitcase": "Валіза",
                "changeFlight": "Змінити квиток",
                "extension": "Продовження",
                "totalToBePaid": "Загальна сума до оплати",
                "choose": "Вибрати",
                "checkAmenities": "Перевірити зручності",
                "description": "Опис",
                "amenityType": "Тип зручностей",
                "flightFromAmenities": "Зручності під час польоту туди",
                "flightBackAmenities": "Зручності під час польоту назад",
                "BAGGAGE": "Багаж",
                "2 CHECKED BAGS UP TO 32KG EACH": "2 зареєстрованих багажі до 32 кг кожний",
                "2 CABIN BAGS UP TO 8KG": "2 ручні багажі до 8 кг",
                "BRANDED_FARES": "Брендові тарифи",
                "STANDARD SEAT RESERVATION": "Стандартне резервування місць",
                "MILEAGE_ACCRUAL": "Нарахування миль",
                "MEAL": "Харчування",
                "SNACK": "Закуска",
                "COMPLIMENTARY FOOD AND BEV": "Безкоштовна їжа та напої",
                "ENTERTAINMENT": "Розваги",
                "MAGAZINES / NEWSPAPER": "Журнали / Газети",
                "LOUNGE": "Зал очікування",
                "LOUNGE ACCESS": "Доступ до залу очікування",
                "TRAVEL_SERVICES": "Туристичні послуги",
                "PRIORITY BAGGAGE": "Пріоритетний багаж",
                "PRIORITY CHECK IN": "Пріоритетна реєстрація",
                "PRIORITY SECURITY": "Пріоритетний контроль безпеки",
                "PRIORITY BOARDING": "Пріоритетна посадка",
                "IN FLIGHT ENTERTAINMENT": "Розваги під час польоту",
                "PRE_RESERVED_SEAT": "Попередньо зарезервоване місце",
                "PRIVACY SEAT BUSINESS CLASS": "Приватне місце бізнес-класу",
                "CHANGE BEFORE DEPARTURE": "Зміна до вильоту",
                "CHANGE AFTER DEPARTURE": "Зміна після вильоту",
                "REFUND BEFORE DEPARTURE": "Повернення коштів до вильоту",
                "REFUND AFTER DEPARTURE": "Повернення коштів після вильоту"
            },
            "room": {
                "roomSize": "Розмір номера",
                "bedType": "Тип ліжка",
                "breakfastIncluded": "Сніданок включено",
                "noAvailabilityOnSelectedDates": "Немає вільних номерів на обрані дати"
            },
            "promotion": {
                "modelNotFound": "Сторінку не знайдено",
                "failToLoad": "Не вдалося завантажити сторінку!"
            },
            "attractions": {
                "showingMoreResults": "Показано більше результатів",
                "noMoreResults": "Більше результатів немає",
                "NoResultsMatchedTheFilterValues": "Немає результатів, що відповідають значенням фільтра",
                "inclusions": "включення",
                "exclusions": "Винятки",
                "overviewOfTheTrip": "Огляд поїздки",
                "immediateConfirmation": "Миттєве підтвердження",
                "pricePerPerson": "Ціна за людину",
                "totalForXXXPeople": "Загальна вартість для XXX осіб",
                "toOrder": "Замовити",
                "freeCancelation": "Безкоштовне скасування",
                "showAll": "Показати деталі",
                "hideAll": "Приховати деталі",
                "days": "дні",
                "rating": "рейтинг",
                "supplier": "постачальник",
                "location": "місцезнаходження",
                "pricePerPersonFrom": "Ціна за людину від",
                "selectDatesAndTravelers": "Виберіть дати та мандрівників",
                "bookNowWithFreeCancellation": "Забронюйте зараз з безкоштовним скасуванням",
                "updateSearch": "Оновити пошук",
                "activities-health-wellness-spas": "Спа",
                "activities-action-entertainment-shows-events": "Шоу та події",
                "activities-nature-outdoors-zoos-aquariums": "Зоопарки та акваріуми",
                "activities-food-drink": "Їжа та напої",
                "activities-action-entertainment-shopping": "Шопінг",
                "activities-nature-outdoors-tickets-passes": "Квитки та перепустки",
                "activities-action-entertainment-rentals-rides": "Оренда та поїздки",
                "activities-transportation": "Транспорт",
                "activities-culture-shows-events": "Шоу та події",
                "activities-nature-outdoors": "Природа та активний відпочинок",
                "activities-tours-by-theme-shopping-tours": "Шопінг тури",
                "activities-workshops-classes-sport-lessons": "Уроки спорту",
                "activities-action-entertainment-tickets-passes": "Квитки та перепустки",
                "activities-workshops-classes-music-dance": "Музика та танці",
                "activities-food-drink-workshops-classes": "Майстер-класи та уроки",
                "activities-health-wellness-relaxation": "Релаксація",
                "activities-tours-by-theme-food-drink-tours": "Тури по їжі та напоях",
                "activities-workshops-classes": "Майстер-класи та уроки",
                "activities-health-wellness-beauty-treatments": "Косметичні процедури",
                "activities-action-entertainment-water": "Вода",
                "activities-workshops-classes-arts-crafts": "Мистецтво та ремесла",
                "activities-tours-by-theme": "Тематичні тури",
                "activities-action-entertainment-land": "Сухопутні",
                "activities-tours-by-theme-sport-tours": "Спортивні тури",
                "activities-culture": "Культура",
                "activities-tours-by-theme-walking-tours": "Піші тури",
                "activities-health-wellness-alternative-medicine": "Альтернативна медицина",
                "activities-tours-by-theme-art-culture-tours": "Тури по мистецтву та культурі",
                "activities-workshops-classes-cultural-immersion": "Занурення в культуру",
                "activities-health-wellness": "Здоров'я та добробут",
                "activities-nature-outdoors-air-activities": "Повітряні активності",
                "activities-tours-by-theme-luxury-tours": "Розкішні тури",
                "activities-tours-by-theme-historical-heritage-tours": "Історичні тури",
                "activities-culture-art-culture-tours": "Тури по мистецтву та культурі",
                "activities-action-entertainment-air": "Повітря",
                "activities-tours-by-theme-nature-wildlife-tours": "Тури по дикій природі",
                "activities-tours-by-theme-discovery-tours": "Тури відкриттів",
                "activities-nature-outdoors-wildlife-nature-observation": "Спостереження за природою та дикою природою",
                "activities-action-entertainment": "Дія та розваги",
                "activities-tours-by-theme-museum-exhibition-tours": "Тури по музеях та виставках",
                "activities-workshops-classes-food-drink": "Їжа та напої",
                "activities-tours-by-theme-virtual-tours": "Віртуальні тури",
                "activities-tours-by-theme-special-tours": "Спеціальні тури",
                "activities-nature-outdoors-land-activities": "Сухопутні активності",
                "activities-transportation-rentals-rides": "Оренда та поїздки",
                "activities-nature-outdoors-water-activities": "Водні активності",
                "activities-food-drink-restaurants-bars": "Ресторани та бари",
                "activities-food-drink-food-drink-tours": "Тури по їжі та напоях",
                "activities-no-category": "Без категорії",
                "activities-health-wellness-retreats": "Ретріти",
                "activities-action-entertainment-sport-lessons": "Уроки спорту",
                "activities-tours-by-theme-self-guided-tours": "Самостійні тури",
                "activities-action-entertainment-multi-adventure-games": "Багатопригодницькі ігри",
                "activities-food-drink-tickets-passes": "Квитки та перепустки",
                "activities-tours-by-theme-night-tours": "Нічні тури",
                "activities-culture-tickets-passes": "Квитки та перепустки",
                "activities-transportation-transfers": "Трансфери",
                "hours": "Години",
            },
            "searchDatePicker": {
                "searchHotelsByNameTitle": "Пошук за назвою готелю для вибраного напрямку",
                "choose_packages_according_to": "Виберіть пакети відповідно до",
                "tech_stops": "технічні зупинки",
                "freePlanFlightSearchFooterText": "Безкоштовний план дозволяє шукати лише чартерні рейси! Якщо ви хочете рейси GDS",
                "clickHere": "Натисніть тут",
                "approveSelection": "Approve Selection",
                "city": "City",
                "hotel": "Hotel",
                "searchByDestinationAndHotel": "Search By Destinations And Hotels",
                "indirectFlight": "Непрямий політ",
                "directFlightLabel": "Прямий авіарейс",
                "date": "Date",
                "search": "Пошук",
                "rooms": "Номери",
                "composition": "Склад",
                "chooseComposition": "Виберіть склад",
                "numberOfGuests": "Кількість гостей",
                "checkOut": "Виїзд",
                "checkIn": "Заселення",
                "whatVacation": "Який вид відпустки",
                "youWant": "ви хочете",
                "vacationFriendsWith": "Відпустка з друзями",
                "familyVacation": "Сімейна відпустка",
                "romanticVacation": "Романтична відпустка",
                "notAvailable": "Недоступно",
                "roomAvailableDuringTheWeek": "Доступність номерів протягом тижня",
                "followingDates": "Наступні дати",
                "vacation": "Відпустка",
                "abroad": "За кордоном",
                "inIsrael": "В країні",
                "destination": "Усі напрямки",
                "chooseDestination": "Виберіть напрямки",
                "children": "Діти",
                "adults": "Дорослі",
                "findDreamVacation": "Знайдіть свою ідеальну відпустку одним кліком.",
                "searchResTitle": "Виберіть правильний пакет для вас",
                "checkHotel": "перевірити готель",
                "noRating": "Без рейтингу",
                "toOrder": "наказувати",

                "dynamicFlight": "Dynamic Flight",
                "bundle": "Bundled Flight",
                "freeCancelation": "Безкоштовне скасування",
                "partially_operated_by_airlines": "Partially operated by airlines",
                "connect_in_airport": "Connect in airport",
                "arrives": "Arrives",
                "journey_duration": "Journey Duration",
                "short_wait": "Short Wait",
                "long_wait": "Long Wait",
                "flight_number": "Flight Number",

            },
            "specialDeal": {
                "closeTheDeal": "Закрийте угоду ›",
                "endsIn": "Закінчується через",
                "specialDealForTheWeekend": "Спеціальна пропозиція на вихідні",
                "from": "З дати",
                "until": "До дати"
            },
            "footer": {
                "backToTop": "Повернутися до верху",
                "allRightsReserved": "© Усі права захищені. Не копіюйте вміст сайту без дозволу.",
                "developBy": "Розроблено SKY 360 Tech.",
                "thePurchaseOnTheWebsiteIsSecure": "Покупка на сайті є безпечною.",
                "talkToUs": "Як з нами спілкуватися?",
                "address": "Адреса",
                "phoneAndWhatsapp": "Телефон або WhatsApp",
                "phone": "Телефон",
                "whatsapp": "Whatsapp",
            },
            "packages": {
                "tour": "Тур",

                "from": "З дати",
                "until": "До дати",
                "breakfastIncluded": "Сніданок включено",
                "includesMassageAndSpa": "Включає масаж і SPA!",
                "lastRoom": "Останній номер!",
                "includesTransfers": "Включає трансфери",
                "includesActivities": "Включає визначні місця",
                "nights": "Ночей",
                "withoutBaggage": "Без багажу",
                "directFlight": "Прямий рейс",
                "oneStop":"Один пересадочний пункт",
                "numOfStops":"Зупинки",
                "flightDetails": "Деталі політів",
                "flightFrom": " from -> to",
                "flightBack": " from -> to",
                "flightNumber": "Номер рейсу",
                "airLine": "Авіакомпанія",
                "suitcaseWeight": "weightкг Багаж включено",
                "trollyWeight": "weightкг Багажна тележка включена",
                "includedActivitiesTitle": "Атракції та екскурсії в пакеті",
                "checkAllPictures": "Переглянути всі фотографії",
                "affiliateWith": "У співпраці з",
                "addButtonDisabledTooltip": "Замовлення обмежено кількістю номерів - не можна додати інший номер. Щоб змінити номер, видаліть номер з кошика",
                "holidaySchedule": "Графік відпусток",
                "passengers":"Пасажири"
            },
            "roomPricing": {
                "for": "для",
                "addPassenger": "Додати пасажира",
                "pricePerPerson": "Ціна за особу",
                "pricePerRoom": "Ціна за номер",
                "priceForAllRooms": "Ціна за всі кімнати",
                "payOnSpot": "Оплата на місці",
                "resort_fee": "Курортний збір",
                "occupancy_tax": "туристичний збір",
                "pricePerInfant": "Ціна за немовля",
                "composition": "Склад",
                "adultsInTheRoom": "Дорослі",
                "children": "Діти",
                "adults": "Дорослі",
                "single": "Один дорослий",
                "child": "Дитина",
                "selectInfantCount": "Виберіть зі списку",
                "infant": "Немовля",
                "1infant": "Одне немовля",
                "2infants": "2 немовля",
                "infants": "Немовлята",
                "noInfants": "Без немовлят",
                "all_include": "Все включено",
                "breakfast": "Сніданок",
                "activities": "Виберіть тур",
                "half_board": "Півпансіон",
                "half_board_kosher": "Кошерний півпансіон",
                "full_board": "Повний пансіон",
                "sleep_only": "Тільки сон",
                "breakfast_kidush": "П'ятниця ранок та вечірній обід",
                "addToCard": "Додати до замовлення",
                "inPackageDates": "В дати пакету",
                "chooseRoom": "Виберіть склад і номер",
                "nights": "Ночей",
                "boardType": "Тип харчування",
                "filters": "Фільтри",
                "noAvailableRooms": "На обрані дати немає доступних номерів",
                "noAvailableRoomsForDates": "Номер не доступний на обрані дати",
                "addInfant": "Додати немовля",
                "show_images": "Show Images",

                //// UPDATE IT LATERRR
                "roomFacilities": "Room Facilities",
                "until": "Until",
                "no": "No",
                "vat": "Vat",
                "is": "Is",
                "not": "Not",
                "city_tax": "City Tax",
                "cancellation_policy": "Cancellation Policy",
                "your_local_time": "*Your local Time",
                "additional_price_info": "Additional Price Info",
                "policy": {
                    "freeCancallationUntil": "Free cancellation at this rate until",
                    "freeCancallationUntil2": "ביטול חינם עד",
                    "cancallationBeCharged": "You will be charged",
                    "ifYouCancelBetween": "If you cancel from",
                    "ifYouCancelModifiedBetween": "If you cancelled or modified after",
                    "cancelFullPrice": "full price of the reservation will be charged",
                    "till": "till",
                    "rateNonRefundable": "The rate is non-refundable upon cancellation",
                }
            },
            "activitiesTranslations": {
                "addToOrder": "Додати до замовлення",
                "passenger": "Для пасажира",
                "showMore": "Показати більше",
                "addAttractionsAndTrips": "Додати атракції та екскурсії"
            },
            "modal": {
                "viewAllImages": "Переглянути всі зображення",
                "galleryTitle": "Галерея фотографій",
                "cancelationTerms": "Умови скасування - ",
                "nameOfTheHotel": "Деталі готелю",
                "hotelInfo": "Про готель",
                "hotelWebsite": "Посилання на веб-сайт готелю",
                "showMore": "Show More",
                "showLess": "Show Less",
            },
            "shoppingCartTranslations": {
                "attractionPriceDescription": "Ціна на атракціон залежить від дати, вибору опцій та кількості квитків",
                "selectedDate": "Выбранная дата",
                "selectADate": "Выберите дату",
                "availableDatesBetween": "Доступные даты между",
                "Options": "Опции",
                "Text": "Текст",
                "Ticket type": "Тип билета",
                "Start Time": "Время начала",
                'Guide language': "Мова довідника",
                "Pick Up Location": "Место отправления",
                "Custom Pick Up Location": "Пользовательское место отправления",
                "Drop Off Location": "Место прибытия",
                "Custom Drop Off Location": "Пользовательское место прибытия",

                "shoppingCartButtonTooltip": "Виберіть склад і додайте до замовлення",
                "totalPrice": "Загальна сума",
                "proceedToPayment": "Перейти до оплати",
                "placeOrder": "Перейти до реєстрації",
                "NoHotelWasChosenForTheVacation": "Готель не було обрано для відпустки",
                "betweenDates": "Між датами",
                "packageInformation": "Деталі пакета",
                "rooms": "Деталі номерів",
                "transfers": "Трансфери",
                "reg_transfers": "Трансфери/шатли до та з готелю",
                "shuttle_transfers": "Шатл з кордону до готелю і назад",
                "flights": "Рейси",
                "activities": "Атракції",
                "shoppingCart": "Кошик",
                "package": "Ночей в готелі",
                "nights": "Ночей",
                "inHotel": "В готелі",
                "apply": "Підтвердити",
                "promoCode": "Промокод",
                "yourShoppingCart": "Ваш кошик",
                "isEmpty": "Досі порожній...",
                "myVacation": "Моя відпустка",
                "showAll": "Показати все »",
                "beforeDiscount": "Загальна сума перед знижкою -",
                "agentCommission": "Агентська комісія -",
                "agentCommissionNet": "Агентська комісія (чистий платіж) -",
                "discountFromCode": "Знижка за кодом -",
                "affiliateWith": "У співпраці з -",
                "tpId": "ID замовлення -",
                "chargeTypeFullCC": "Повна оплата - включаючи комісію",
                "chargeTypeNetCC": "Оплата за чисту вартість",
                "chargeTypeCash": "Готівкова операція",
                "agentCommissionPrec": "Відсоток агентської комісії",
                "priceOff": "Знижка",
                "percentageOff": "Відсоток знижки",
                "selectedItems": "Вибрані товари",
                "viewProduct": "Переглянути товар",
                "attractionSearchFailed": "Не вдалося знайти атракцію",
                "searchOtherForDates": "Немає доступності, будь ласка, оберіть інші доступні дати",
                "cannotSearchForThePastDates": "Неможливо здійснити пошук за минулими датами",
                "attractionAlreadyAdded": "Неможливо додати однаковий тип атракції, будь ласка, оберіть інший тип атракції",
                "failedToAddAttraction": "Не вдалося додати атракцію",
                "noAvailabilityOnThisDates": "Немає доступності на вибрані дати",
            },
            "checkout": {
                "free": "безкоштовно",
                "room": "Номер",
                "personalInfo": "Дані відпочиваючих",
                "paymentDetails": "Деталі платежу",
                "orderCompletion": "Завершення замовлення",
                "userDetails": "Деталі замовника",
                "name": "Ім'я",
                "lastName": "Прізвище",
                "passport": "Паспорт",
                "inEnlgish": "",
                "birthdate": "Дата народження",
                "phone": "Телефон",
                "email": "Електронна пошта",
                "notesForTheOrder": "Примітки до замовлення",
                "namesOfGuests": "Імена пасажирів",
                "namesMustMatchPassport": "* Усі імена повинні бути введені англійською мовою точно так, як вони вказані в паспорті",
                "goToPayments": "Підтвердити та перейти до оплати",
                "saveOrder": "Зберегти замовлення",
                "agreement": "Я підтверджую умови веб-сайту, умови замовлення та умови скасування замовлення",
                "men": "Чоловік",
                "women": "Жінка",
                "pleaseInputYourPhone": "Будь ласка, введіть номер телефону",
                "pleaseInputYourLastName": "Будь ласка, введіть ваше прізвище",
                "pleaseInputYourFirstName": "Будь ласка, введіть ваше ім'я",
                "pleaseInputYourEmail": "Будь ласка, введіть вашу електронну пошту",
                "pleaseInputYourPassportNumber": "Будь ласка, введіть номер вашого паспорта",
                "pleaseInputYourDateOfBirth": "Будь ласка, введіть вашу дату народження",
                "pleaseSelectGender": "Будь ласка, виберіть стать",
                "maxChildAge": "Дитина віком від 2 років -",
                "maxInfantAge": "Немовля повинно бути менше двох років на момент відпустки",
                "pleaseAgreeWithT&C": "Будь ласка, погодьтеся з умовами використання та правилами веб-сайту",
                "viewTerms": "Переглянути правила",
                "invalidEmail": "Недійсна електронна пошта",
                "orderCreateSuccess": "Ваше замовлення успішно збережено, вас перенаправляють до оплати",
                "orderCreateFree": "Ваше замовлення успішно збережено!",
                "orderUpdateSuccess": "Ваше замовлення успішно оновлено!",
                "mainRoomGuestTitle": "Номер на ім'я",
                "uploadPassportImage": "Завантажити фотографію паспорта",
                "addInfant": "Додати немовля",
                "updateInfant": "Оновити кількість немовлят",
                "howManyInfantsDoYouWantToAdd": "Виберіть кількість немовлят для цього номера",
                "add": "Додати",
                "setInfantCountForRoom": "Оновити кількість немовлят",
                "setInfantCountForRoomSuccess": "Кількість немовлят успішно оновлено",
                "infantsAddedToTheRoom": "Немовлята додані до номера",
                "selectNumberOfInfants": "Виберіть кількість немовлят",
                "pricePerInfant": "Ціна за немовля",
                "infantsRemoved": "Немовлята були видалені з цього номера",
                "requiredUploadPassportImage": "Завантаження фотографії паспорта обов'язкове",
                "infantError4403": "Неможливо змінити кількість немовлят - будь ласка, зв'яжіться з центром обслуговування",
                "docketId": "* ID документа",
                "agencyId": "* ID агентства"
            },
            "manageOrder": {
                "moveToPayment": "Додати спосіб оплати",
                "backToManage": "Повернутися до управління замовленням",
                "paymentTitle": "Додати спосіб оплати",
                "manageTitle": "Оновлення даних відпочиваючих",
                "modelNotFound": "Замовлення не знайдено",
                "orderId": "Управління замовленням"
            },
            "completionForm": {
                "paymentFinish": {
                    "title": "Замовлення успішно зроблене - оплата завершена",
                    "info": "На вашу адресу відправлено електронний лист із деталями замовлення"
                },
                "waitingToApprove": {
                    "title": "Ваше замовлення надіслано на схвалення",
                    "info1": "Деталі замовлення збережено в системі та надіслано на схвалення.",
                    "info2": "Оплата буде здійснена лише після схвалення замовлення.",
                    "info3": "Після схвалення замовлення вас повідомлять електронною поштою про підтвердження та оплату."
                },
                "paymentFail": {
                    "title": "Оплата не вдалася",
                    "info1": "Оплата не вдалася :(",
                    "infoFail": "Після декількох спроб оплати вона не вдалася - спробуйте ще раз",
                    "infoWhatsapp": "Якщо виникла помилка та ви не можете оплатити, зв'яжіться через WhatsApp за адресою: "
                },
                "orderNum": "Номер замовлення",
                "thanksForChoose": "Дякуємо за вибір",
                "free": {
                    "title": "Реєстрація пройшла успішно",
                    "info": "На вашу адресу відправлено електронний лист із підсумками замовлення"
                },
                "pay_later": {
                    "title": "Реєстрація пройшла успішно",
                    "info": "На вашу адресу відправлено електронний лист із підтвердженням замовлення"
                }
            },
            "paymentForm": {
                "creditNotSaveInBd": "Дані кредитної картки не зберігаються на сайті - ви робите безпечний платіж",
                "title": "Введіть спосіб оплати (лише Visa і Mastercard)",
                "splitBtn": "Клацніть, щоб розділити кредитні карти",
                "splitTitle": "Розділити кредитні карти",
                "splitDesc": "Виберіть кількість кредитних карток для розділення та клацніть «Підтвердити»",
                "noSplit": "Без поділу",
                "noPayments": "Без оплати",
                "payments": "Оплати",
                "paymentsNum": "Кількість платежів",
                "withDebit": "З кредитом",
                "splitBy": "Кредитні карти",
                "toPay": "До оплати",
                "splitApprove": "Підтвердити",
                "currencyRate": "Сплата здійснюється в шекелях - за туристичним курсом",
                "splitCurrentCardNum": "Розділити кредит",
                "splitCurrentCardNumFrom": "З",
                "errorMessageToRetry": "Спроба № {retryNum} - не вдалося здійснити платіж від компанії, що видає кредитну картку. Введіть дані картки ще раз або іншу картку. Веб-сайт приймає лише картки VISA та MASTERCARD.",
            },
            "hotelTypes": {
                "Hotel": "Hotel",
            },
            "hotelDetails": {
                "updateDates": "Оновити дати",
                "roomTypesToChooseFrom": "Виберіть типи номерів",

                "metapolicy": "מדיניות המלון",
                "metaExtraInfo": "פרטים נוספים",
                "not_included": "לא כלול",
                "included": "כלול",
                "price": "בעלות של",
                "address": "כתובת",
                "other": "כתובת",
                "Location": "מיקום מלון",
                "At the boutique hotel ": "על המלון",
                "Room amenities": "שירות חדרים",

                "parking": "חניה",
                "pets": "חיות מחמד",
                "shuttle": "הסעה לשדה (לכיוון)",
                "internet": "שירותי אינטרנט",
                "extra_bed": "מיטה נוספת",
                "add_fee": "תוספת תשלום",
                "children_meal": "ארוחת ילדים",
                "check_in_check_out": "צ׳ק אין וצ׳ק אווט",

            },
            "sideFilters": {
                "sortBy": "Сортувати за",
                "recommended": "Рекомендовано",
                "price": "Ціна",
                "clearSideFilters": "Очистити фільтри",
                "ts_type": "Типи рейсів",
                "auto_approve": "Миттєве підтвердження",
                "include_transfer": "Включити трансфер",
                "include_baggage": "Включити багаж",
                "include_trolly": "Включити візок",
                "auto_approve_checked": "Показувати результати з миттєвим підтвердженням",
                "from_date_hours": "Час вильоту",
                "return_date_hours": "Час повернення",
                "airline": "Авіакомпанія",
                "2": "Чартерні рейси",
                "3": "Регулярні рейси",
                "nonStop": "Без пересадок",
                "stop": "Одна пересадка",
                "stops_plural": "Пересадки",
                "hotel_type": "Типи проживання",
                "stops": "Кількість пересадок",
                "freeCancellation": "Безкоштовне скасування?",
                "freeCancellationLabels": {
                    "1": "Тільки з безкоштовним скасуванням",
                    "0": "Без безкоштовного скасування"
                },

                "feature": "Особливість",
            },
            "package_feature": {
                "SPECIAL PACKAGE": "Спеціальний пакет",
                "ALL PACKAGES": "Усі пакети",
                "KOSHER": "Кошерний",
                "TOUR": "Тур",
                "SKI": "Лижі",
                "EVENT": "Подія",
                "SPORT": "Спорт",
                "SPA": "Спа",
                "YOUNG VACATION": "Молодіжний відпочинок",
                "FLT+HTL+CAR": "Політ+Готель+Авто"
            },
            "hotelAmenities": {
                // "dummy": "dummy"
            },
            "general": {
                "language": "Мова",
                "remark": "Примітка",
                "addService": "Додати послугу",

                "total": "Разом",
                "as_of_today": "станом на сьогодні",
                "link_to_the_offer": "Посилання на пропозицію",
                "the_quote_has_been_copied_successfully": "Цитата успішно скопійована",
                "base": "Основа",
                "submit": "Submit",
                "maxAmountOf": "Максимальна кількість",
                "minAmountOf": "Мінімальна сума",
                "ticketType": "Тип квитка",
                "cancellation_policy": "Політика скасування",
                "additionalInfo": "Додаткова інформація",
                "hour": "година",
                "minute": "хвилина",
                "before": "раніше",
                "date": "Дата",
                "duration": "Тривалість",
                "quantity": "Кількість",
                "for":"для",
                "free": "безкоштовно",
                "freeOfCharge": "Безкоштовно",
                'option': 'Варіант',
                'removeSelection': 'Зняти виділення',
                'grandTotalPrice': 'Загальна ціна',
                'startingPrice': 'стартова ціна',
                "usd": "USD",
                "euro": "EURO",
                "address": "Адреса",
                "type": "Тип",
                "noResults": "Немає результатів",
                "nights": "Ночей",
                "includesActivities": "{NUMBER} подарункових атракцій!",
                "includesActivity": "Включає подарунковий атракціон!",
                "adults": "Дорослі",
                "all_include": "Все включено",
                "breakfast": "Сніданок",
                "half_board": "Півпансіон",
                "half_board_kosher": "Кошерний півпансіон",
                "full_board": "Повний пансіон",
                "sleep_only": "Тільки проживання",
                "breakfast_kidush": "П'ятниця сніданок та обід",
                "addToCard": "Додати до замовлення",
                "chooseRoom": "Виберіть склад і номер",
                "boardType": "Тип розміщення",
                "filters": "Фільтри",
                "rating": "Рейтинг готелю",
                "attraction_stars": "Рейтинг атракції",
                "name": "Назва",
                "taxonomies": "Таксономії",
                "children": "Діти",
                "toDate": "До",
                "transfers": "Трансфери",
                "from": "Починаючи з",
                "totalRoomPrice": "Загальна сума",
                "perPerson": "За особу",
                "perPax": "за одиницю",
                "include": "Включає",
                "noIncludeText": "Пакети відпусток",
                "flights": "Рейси",
                "vacationAt": "Відпустка в {DEST}",
                "fieldRequired": "Обов'язкове поле",

                "h": "h",
                "min": "min",

                "loading": "Завантаження",
                "showingMoreResults": "Показ додаткових результатів",
                "noMoreResults": "Більше результатів немає",
                "NoResultsMatchedTheFilterValues": "Немає результатів, що відповідають параметрам фільтру"
            },
            "subsidizedForm": {
                "title": "Додати субсидію",
                "employee_id": "Номер співробітника",
                "person_id": "Ідентифікаційний номер (9 цифр)",
                "validate": "Перевірити код співробітника",
                "alreadyApplied": "Субсидію вже застосовано",
                "addOneMore": "Додати ще одну субсидію",
                "addOneMoreId": "Додати іншого співробітника",
                "fieldRequired": "Обов'язкове поле",
                "priceReducedBy": "Ціна знижена на",
                "forEmployee": "Для співробітника",
                "employeeIs": "Успішно ідентифікований",
                "error4401": "Не вдалося ідентифікувати, спробуйте ще раз",
                "error4402": "Код ідентифіковано, але вже використовується",
                "guestIdentifiedId": "Ідентифікований за ідентифікаційним номером",
                "guestNotIdentified": "Ви повинні ідентифікуватися перед реєстрацією",
                "clickToIdentify": "Клацніть, щоб ідентифікувати"
            },
            "uploadImage": {
                "uploadPassportImage": "Завантажити фото паспорта",
                "somethingWentWrong": "Щось пішло не так"
            },
            "flightButton":{
                "no_availability_for_this_flight_price": "Вибачте, але більше немає доступності за цією ціною на переліт",
                "continueToOrder":"מעבר להזמנה",
                "addToCart":"הוסף לסל",
                "lastPlace":"מקומות אחרונים",
                "pricePerPassenger":"מחיר לנוסע",
                "cardConditions": "Умови картки",
            },
            "agentCommission":{
                'supplier':"ספק",
                'commission':"עמלה",
                "recommendedFee":"עמלה",
                "transactionDetailsForAgent":"פרטי עסקה לסוכן",
                "supplierNotes":"הערות ספק",
                "cancellationConditions":"ותנאי ביטול",
                "addToCartToChangeFree":"הוסף לסל לשינוי עמלה",
                "pricesShownAreNetPrices":"המחירים המוצגים הינם מחירי נטו",
                "packageRemarks": "package remarks",
            }
        }
    }
};

export default translationsEn;