import React, { useMemo } from 'react';
import './flightCard2.less';
import { FaExclamationCircle } from 'react-icons/fa';
import { Col, Row, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { Flight, FlightType } from '../../../../interfaces/flightInterface';
import FlightButton2 from '../../../../components/FlightButton/FlightButton2';
import FlightOutLineSection from './FlightOutLineSection/FlightOutLineSection';
import { Product } from '../../../ShoppingCart/ShoppingCartSliceV2';
import AgentCommissionSearch from '../../../../components/AgentCommissionSearch/AgentCommissionSearch';
import AmenitiesTooltip from '../../../ViewFlight/AdditionalFaresFlightBox/AmenitiesTooltip';
interface AirlineLogoWithToolTipProps {
  airline: any;
  isFlightExist: boolean;
  firstFlight?: any
}

const AirlineLogoWithToolTip = ({
  airline,
  isFlightExist,
  firstFlight
}: AirlineLogoWithToolTipProps) => {
  const { searchDatePicker: t, general: t_general } = useSelector(
    (data: RootState) => data.config.translations
  );

  return (
    <div  className="airline-logo-with-tooltip-wrapper">
    {firstFlight  && <div className="amenities-tooltip">
        <AmenitiesTooltip flightFrom={firstFlight} isShowFullText={false}/>
    </div>}
    <Tooltip title={airline.name + ' - ' + t.airlineCode + ' ' + airline.id}>
      <div className="airline-logo-with-tooltip">
        {isFlightExist && airline.image ? (
          <img src={airline.image} className="logo" />
        ) : (
          <span>{airline.id}</span>
        )}
        <div className="airline-info">
          <FaExclamationCircle />
          <span>{t.airlineInformation}</span>
        </div>
      </div>
    </Tooltip>
    </div>
  );
};
interface Props {
  flightData: any;
  handlePurchase: (type: FlightType, f1: any) => void;
  isShowFlightButton?: boolean;
  isMinimized?: boolean;
  flightFromSubProducts?: Product[];
  flightBackSubProducts?: Product[];
  buttonText?: string;
  isPreviewMode?: boolean;
  showProductPrice?: boolean;
}

export const modifyFlightData = (data: any, isFlightBack?: boolean) => {
  if (!data) {
    return undefined;
  }

  const isFlightsSegments = Array.isArray(data);
  const firstEl = isFlightsSegments ? data[0] : data;
  const lastEl = isFlightsSegments ? data[data?.length - 1] : data;
  const isWithoutStops = isFlightsSegments ? true : data?.length > 1;
  const stopLocationCodes = [];
  const operatedByAirlineLogos = new Set();
  const operatedAirlinesNames = new Set();
  const stopAirlineNames: any = [];

  for (let i = 0; i < data.length; i++) {
    stopLocationCodes.push(data[i].to.code);

    if (!stopAirlineNames.includes(data[i].airline.name)) {
      stopAirlineNames.push(data[i].airline.name);
    }

    if (data[i].operated_airline) {
      if (data[i].operated_airline.id !== data[i].airline.id) {
        operatedByAirlineLogos.add(data[i].operated_airline.image);
        operatedAirlinesNames.add(data[i].operated_airline.name);
      }
    }
  }

  const newObj = {
    operatedBy: operatedAirlinesNames.size > 0 ? Array.from(operatedAirlinesNames).join(' ; ') : false,
    operatedByAirlineLogos: Array.from(operatedByAirlineLogos),
    flight_number: isWithoutStops
        ? firstEl?.flight_number
        : `${firstEl?.flight_number}-${lastEl?.flight_number}`,
    flight_joint_name: isWithoutStops
        ? `${firstEl?.airline.id}-${firstEl?.flight_number}`
        : `${firstEl?.airline.id}-${firstEl?.flight_number} ; ${lastEl?.airline.id}-${lastEl?.flight_number}`,
    stop_location_codes: stopLocationCodes.join(' - '),
    stop_airline_names: stopAirlineNames.join(', '),
    from: firstEl?.from,
    departure_date: firstEl?.departure_date,
    baggage: firstEl?.baggage,
    num_of_stops: data?.length - 1,
    to: lastEl?.to,
    arrival_date: lastEl?.arrival_date,
    available_seats: firstEl?.available_seats ? Number(firstEl?.available_seats) : -1,
  };

  return newObj;
};

const FlightCard2 = ({
  flightData: data,
  handlePurchase,
  isShowFlightButton = true,
  isMinimized = false,
  flightFromSubProducts,
  flightBackSubProducts,
  buttonText,
  isPreviewMode,
  showProductPrice = true,
}: Props) => {
  const { searchParams } = useSelector((data: RootState) => data.search);
  const { searchDatePicker: t, general: t_general } = useSelector(
    (data: RootState) => data.config.translations
  );

  const flightData = data.flightsInfo ? data.flightsInfo : data;

  const flightKey = flightData?.flightFrom ? 'flightFrom' : 'flight';

  const isRoundTrip = flightData?.[flightKey][0].baggage.is_round_trip;

  const isOneWayFlight =
    (!flightData?.[flightKey] && flightData?.flightBack) ||
    (flightData?.[flightKey] && !flightData?.flightBack);

  const airLine = flightData?.[flightKey]
    ? flightData[flightKey][0]?.airline
    : flightData.flightBack[0]?.airline;

  const flightFromShortData = useMemo(() => {
    return modifyFlightData(flightData?.[flightKey]);
  }, [flightData?.[flightKey]]);

  const flightBackShortData = useMemo(() => {
    return modifyFlightData(flightData?.flightBack);
  }, [flightData?.flightBack]);

  const pax = {
    adult: Number(searchParams.adult),
    child: Number(searchParams.child),
    infant: Number(searchParams.infant),
  };

  const isSameAirline = !Array.isArray(flightData[flightKey])
    ? () => {
        return (
          isOneWayFlight ||
          flightData[flightKey].airline.id === flightData.flightBack.airline.id
        );
      }
    : () => {
        const fromAirlines = flightData[flightKey].map(
          (flight: Flight) => flight.airline.name
        );
        if (!flightData?.flightBack) {
          return fromAirlines.every(
            (airline: string, index: number, arr: string[]) =>
              airline === arr[0]
          );
        }

        const backAirlines = flightData.flightBack.map(
          (flight: Flight) => flight.airline.name
        );

        return fromAirlines.every(
          (airline: string, index: number, arr: string[]) =>
            airline === backAirlines[index] && airline === arr[0]
        );
      };

  return (
    <div>
      <div
        className={`flight-card-2 ${
          isMinimized ? 'flight-card-2-minimized' : ''
        }`}
      >
        <Row>
          {!isMinimized && (
            <Col md={isMinimized ? 24 : 5} xs={24} className="airline-wrapper">
              {isSameAirline() ? (
                <AirlineLogoWithToolTip airline={airLine} isFlightExist />
              ) : (
                <>
                  <AirlineLogoWithToolTip
                    airline={flightData?.[flightKey]?.[0]?.airline}
                    isFlightExist={flightData?.[flightKey]}
                    firstFlight={flightData?.[flightKey]?.[0]}
                  />
                  {flightData?.flightBack && (
                    <AirlineLogoWithToolTip
                      airline={flightData?.flightBack?.[0]?.airline}
                      isFlightExist={flightData?.flightBack}
                      firstFlight={flightData?.flightBack?.[0]}
                    />
                  )}
                </>
              )}
            </Col>
          )}
          <Col md={isMinimized ? 24 : 19} xs={24} className="flights-wrapper">
            {isMinimized && <div className="dotted-border-top" />}

            {isMinimized && (
              <AirlineLogoWithToolTip
                firstFlight={flightData?.[flightKey]?.[0]}
                airline={flightData?.[flightKey]?.[0]?.airline}
                isFlightExist={flightData?.[flightKey]}
              />
            )}

            {flightData?.[flightKey] && (
              <FlightOutLineSection
                flightData={flightData?.[flightKey]}
                flightOutlineData={flightFromShortData}
                flightSubProducts={flightFromSubProducts}
                isMinimized={isMinimized}
                flightIdentifier={flightData.identifier}
                showProductPrice={showProductPrice}
              />
            )}

            {flightData?.flightBack && <div className="dotted-border-top" />}

            {isMinimized && flightData?.flightBack && (
              <AirlineLogoWithToolTip
                airline={flightData?.flightBack?.[0]?.airline}
                isFlightExist={flightData?.flightBack}
                firstFlight={flightData?.flightBack?.[0]}
              />
            )}

            {flightBackShortData && (
              <FlightOutLineSection
                flightData={flightData?.flightBack}
                flightOutlineData={flightBackShortData}
                flightSubProducts={
                  isRoundTrip ? flightFromSubProducts : flightBackSubProducts
                }
                isMinimized={isMinimized}
                // backFlight
                flightIdentifier={flightData.identifier}
                showProductPrice={showProductPrice}
              />
            )}
          </Col>
        </Row>

        {!isPreviewMode && isShowFlightButton && (
          <FlightButton2
            flightPrice={flightData.price}
            pax={pax}
            handlePurchase={handlePurchase}
            f1={flightData.identifier}
            type={FlightType.BundleFlight}
            suggested_commission={
              flightData?.agent_additional_info?.suggested_commission
            }
            buttonText={buttonText}
            showProductPrice={showProductPrice}
            noAvailabilityForThisPrice={flightData?.available === false}
          />
        )}
      </div>
      {!isMinimized && (
        <AgentCommissionSearch
          centered
          agentAdditionalInfo={flightData?.agent_additional_info}
        />
      )}
    </div>
  );
};

export default FlightCard2;
