import { AgentAdditionalInfo } from "../ShoppingCart/agentActions/AgentActions";

export type PriceUnitTypes =
  | 'PER_PERSON'
  | 'PER_VEHICLE'
  | 'GROUP'
  | 'FAMILY'
  | 'PER_EQUIPMENT'
  | 'PACKAGE'
  | 'PER_BOAT'
  | 'NONE';

export enum PriceUnit {
  perPerson = 'PER_PERSON',
  perVehicle = 'PER_VEHICLE',
  group = 'GROUP',
  family = 'FAMILY',
  perEquipment = 'PER_EQUIPMENT',
  package = 'PACKAGE',
  perBoat = 'PER_BOAT',
  none = 'NONE',
}

export enum PriceUnitLabels {
  adult = 'Adult',
  senior = 'Senior',
  child = 'Child',
  children = 'Children',
  student = 'Student',
  youth = 'Youth',
  infant = 'Infant',
}

// export enum PriceUnitIds {
//   adult = 'ADULT',
//   senior = 'SENIOR',
//   child = 'CHILD',
//   youth = 'YOUTH',
//   infant = 'INFANT',
// }

export interface AvailabilityOption {
  id: string;
  label: string;
  level: string;
  dataType: string;
  selectedOptionId: string;
  selectedOptionValue: string;
  optionChoices: Record<string, string>;
}

export interface AvailabilityObject {
  id: string;
  availabilityDate: string; // Use `Date` if you want to handle this as a Date object
  availabilityOptions: AvailabilityOption[];
  unitOptions: UnitOption[];
  maxParticipants: number;
  minParticipants: number;
  isOptionStepCompleted: boolean;
  isPricingStepCompleted: boolean;
}

export interface UnitOption {
  label: PriceUnitLabels | string;
  id: string;
  restrictions: Restrictions;
  price: Price;
  unitQuantity: number;
  minAge: number;
  maxAge: number;
  priceUnitType: PriceUnitTypes;
}

export interface AvailabilityProductDetails {
  id?: string | number;
  productId?: number;
  availabilityDateTime?: string;
  resellerRef?: string;
  status?: string;
  paymentStatus?: string;
  creationDate?: string;
  updateDate?: string;
  availability?: AvailabilityObject;
  questions?: Questions;
  cancellationInformation?: CancellationInformation;
  isQuestionStepComplete?: boolean;
  travelerDetails?: TravelerDetails;
}

export interface PriceOptionType extends AvailabilityObject {
  maxParticipants: number;
  minParticipants: number;
  unitOptions: UnitOption[];
  isOptionStepCompleted: true;
  isPricingStepCompleted: false;
}

interface GeoLocation {
  latitude: number;
  longitude: number;
  geoHash: string;
}

interface GalleryImage {
  url: string;
  order: number;
}

interface Category {
  level: number;
  code: string;
}

interface Supplier {
  id: number;
  name: string;
  externalId: string;
  creationDate: string;
  updateDate: string;
  contact: object;
  countryCode: string;
  countryName: string;
}

interface Descriptor {
  type: string;
  reason: string;
}
export interface Taxonomy {
  uuid: string;
  name: string;
  level: number;
  slug: string;
  family: string;
  parentUuid: string;
  version: number;
  isCurrent: boolean;
  startDate: string;
  nameTranslation: string;
  value: number;
  nameFullPath?: string;
}

export interface AttractionProduct {
  id: number;
  identifier: string;
  agent_additional_info?: AgentAdditionalInfo;
  externalId?: string;
  title?: string;
  creationDate?: string;
  updateDate?: string;
  availabilityType?: string;
  shortDescription?: string;
  description: string;
  thumbnailImage?: string;
  bookingEngine?: string;
  bookingEngineName?: string;
  bookingUrl?: string;
  minPrice: number;
  maxPrice: number;
  priceCurrency?: string;
  pricingUnit?: string;
  address?: string;
  geoLocation?: GeoLocation;
  duration?: string;
  durationHours?: number;
  galleryImages: GalleryImage[];
  categories: Category[];
  supplier: Supplier;
  onlineBookable?: boolean;
  descriptors: Descriptor[];
  availableLanguages?: string[];
  periods?: any[];
  cityName?: string;
  countryName?: string;
  taxonomies: Taxonomy[][];

  availabilityPeriods?: object;
  isCancellable?: boolean;
  cancellationCutOffAmount?: number;
  cancellationCutOffUnit?: string;
  cancellationCutOff?: string;
  modalitiesAdditionalInfo?: string;
  handiAccess?: boolean;
  displayedLanguage?: string;
  rating?: number;
  numberOfRatings?: number;
}

export interface AttractionOrderProduct {
  attraction: AttractionProduct;
  optionPrice: PriceOptionType;
  quantityData: Record<
    string,
    {
      count: number;
      price: number;
    }
  >;
}

export interface Price {
  retail: number;
  c_price: number;
  currency: string;
}

export interface Questions {
  // booking: BookingQuestion[]; // this key also exists but is always an empty arr, review with Nir, TODO:
  customer: CustomerQuestion[];
  participants: Participant[];
}

export type QuestionDataType =
| 'TEXT'
| 'INT'
| 'DATE'
| 'DATE_TIME'
| 'OPTIONS';

export type AdditionalQuestionDataType =
| 'PHONE_NUMBER'
| 'EMAIL_ADDRESS';

export type QuestionDataFormatType =
| QuestionDataType
| AdditionalQuestionDataType;

export enum QuestionDataFormatEnum {
  TEXT = 'TEXT',
  INT = 'INT',
  DATE_TIME = 'DATE_TIME',
  DATE = 'DATE',
  OPTIONS = 'OPTIONS',
  PHONE = 'PHONE_NUMBER',
  EMAIL = 'EMAIL_ADDRESS',
}

export interface Participant {
  id: string;
  questions: ParticipantQuestion[];
}

export interface CustomerQuestion { // same as ParticipantQuestion ??
  id: string;
  question: string;
  questionChoices?: any;

  value?: string; // Optional
  dataType?: QuestionDataType; // Optional
  dataFormat?: QuestionDataFormatType; // Optional
}
export interface BookingQuestion { // same as ParticipantQuestion ??
  id: string;
  question: string;
  questionChoices?: any;
  value?: string; // Optional
  dataType?: QuestionDataType; // Optional
  dataFormat?: QuestionDataFormatType; // Optional
}
export interface ParticipantQuestion { // same as CustomerQuestion ??
  id: string;
  question: string;
  questionChoices?: Record<string, string>;

  value?: string; // Optional
  dataType?: QuestionDataType; // Optional
  dataFormat?: QuestionDataFormatType; // Optional
}

export interface CancellationInformation {
  cancellation: Cancellation;
}

export interface Cancellation {
  isCancellable: boolean;
  cancellationPolicy: string[];
}

export interface TravelerDetails {
  externalClientId: string;
}

export interface Restrictions {
  minQuantity: number;
  maxQuantity: number;
  accompagniedBy: {unitId: string, label: string}[]; // List of accompanying rules
}

export enum ErrorCode {
  MISSING_DATA = 'missing_data',
  DEFAULT_ERROR = 'default_error',
}