const endpoints = {
    AGENT: {
        POST_INFO: "/supplier/site/agentInfo",
        CREATE_USER:"/supplier/customer/create",
        UPDATE_USER:"/supplier/customer/update",
        UPDATE_COMMISSION:"/ms/booking/order/updateAgentCommission",
        CUSTOMER_SEARCH:"/supplier/agency/customerSearch",
        GET_UPGRADE_REQUEST:"/supplier/subscription/upgradeRequest",
},
    ORDER: {
        CREATE: "/b2c/order/create",
        UPDATE: "/b2c/order/update",
        // GET: "/b2c/order/get",
        GET: "/ms/booking/order",
        CHECK_DISCOUNT_CODE:"/b2c/order/check-code",
        UPLOAD_ATTACHMENT:"/b2c/order/UploadGuestAttachment",
        UPDATE_INFANTS:"/b2c/order/updateInfantCount",
        CREATE_MS:'/ms/booking/order',
        UPDATE_GUESTS:"/ms/booking/order/updatePassengerInfo",
    },
    PAYMENT: {
        FORM: "/b2c/payment/form",
        FORM_V2: "/b2c/payment/formV2",
        CUSTOM_DATA_UPDATE: "/b2c/payment/custom-data-update",
        TRANSACTION_STATUS: "/b2c/payment/transaction-status"
    },
    PROMOTION: {
        GET: "/b2c/package/promotion",
        VALIDATE_SUBSIDIZE:"b2c/group/validateEmployee"
    },
    SITE: {
        UINFO: "/b2c/site/uinfo",
        PAGE: "/b2c/site/page",
    },
    PACKAGE: {
        CANCELATION: "/b2c/destination/cancellationTerms",
        GET:'/b2c/package/get',
        // GET_MS:'/ms/product-view/package', // Tamta
        GET_MS:'/ms/product-info/package/info', // Tamta
        FILTER:'/b2c/package/filter',
        SEARCH_MS:'/ms/product-search/package/search',
        SEARCH:'/b2c/package/filter',
        GET_PRICE:"/b2c/package/price",
        GET_PRODUCT_TERMS_MS:'/ms/product-info/terms',
    },
    CONCEPT:{
        INDEX:'/b2c/concept/index',
        PACKAGES:'/b2c/concept/packages',
        VIEW:"/b2c/concept/view"
    },
    ACTIVITIES: {
        GET : '/b2c/activity/get'
    },
    APP:{
        config:'/b2c/site/config',
        translations:"/b2c/site/tran",
        contactUs:"/b2c/site/contactUs"
    },
    HOTEL:{
        // GET:"/b2c/hotel/price",
        // GET_HOTEL: "/b2c/hotel/get", // OLD API
        // VIEW:"/b2c/hotel/view",
        // PRICE:"/b2c/hotel/price",
        SEARCH: "/ms/product-search/hotel/search",
        SEARCH_BY_IDs: "/ms/product-search/hotel/search-by-ids",
        VIEW:"/ms/product-info/hotel",
        GET_HOTEL_PRICE:"/ms/product-info/hotel/price"
    },
    DESTINATIONS:{
        GET:"/b2c/destination/index"
    },
    FLIGHTS:{
        // SEARCH_ONE_WAY
        GET:"/ms/product-search/flight/single-search",
        // SEARCH_BUNDLE
        GET_BUNDLE:"/ms/product-search/flight/bundle-search",
        VIEW_ADDITIONAL_FARES:"/ms/product-info/flight/get-additional-fares",
        VIEW_FLIGHT_MS:"/ms/product-view/flight",
        VIEW_BUNDLE_MS:"/ms/product-view/flight",
        VIEW_FLIGHT_ALP:"/ms/product-info/flight/info",
        VIEW_FLIGHT:"/b2c/flight/get"
    },
    ATTRACTIONS:{
        SEARCH:"/ms/product-search/activity/search",
        GET_ATTRACTION:"/ms/product-info/activity/info",
        GET_AVAILABILITY:"/ms/ts-amd-discover-adapter/activity/product-availability",
        GET_DATE_OPTIONS:"/ms/ts-amd-discover-adapter/activity/product-options",
        POST_PRICE:"/ms/product-info/activity/price",

        POST_BOOKING:"/ms/ts-amd-discover-adapter/activity/booking",
        UPDATE_BOOKING_UNITS:"/ms/ts-amd-discover-adapter/activity/booking/update-units",
    },
    AUTOCOMPLETE:{
        CITY:"/ms/product-search/autocomplete/city",
        HOTEL:"/ms/product-search/autocomplete/hotel",
        AIRPORT:"/ms/product-search/autocomplete/airports",
        GET_AIRPORT:"/ms/product-search/loacationByIata",
        GET_CITY:"/ms/product-search/city",
        GET_CALENDAR:"/ms/product-search/calendar",
        GET_BY_TS:"/ms/product-search/autocomplete/ts"
    }
};

export default endpoints;
