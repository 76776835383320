import { Modal, Select } from 'antd';
import './inputWithMovingPlaceholder.less';
import React, { useRef, useState } from 'react';
import { Input } from 'antd';

interface InputWithMovingPlaceholderProps
  extends React.ComponentProps<typeof Input> {
  placeholder?: string;
  onChange: any;
  value: any;
  type?: string | undefined;
  disabled?: boolean;
  required?: boolean;
  options?: { value: string; label: string }[] | [];
}

const InputWithMovingPlaceholder = ({
  disabled,
  placeholder,
  value = '',
  onChange,
  type = 'text',
  required,
  options,
}: InputWithMovingPlaceholderProps) => {
  const [focused, setFocused] = useState(false);

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    if (value === '') {
      setFocused(false);
    }
  };

  const handleChange = (e: any) => {
    if (type === 'date') {
      return onChange(e);
    }
    onChange(e);
  };

  const handleClickPlaceholder = () => {
    setFocused(true);
    ref.current.focus();
  };

  const ref = useRef<any>(null);
  return (
    <div
      className={`input-with-moving-placeholder ${
        focused || value !== '' ? 'ant-input-focused' : ''
      }`}
    >
      {placeholder && (
        <span className="placeholder" onClick={handleClickPlaceholder}>
          {placeholder}
        </span>
      )}

      {type === 'render_custom_input_select_dropdown_options' ? (
        <Select
          ref={ref}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          disabled={disabled}
          value={value}
          showSearch
          placeholder={placeholder}
          filterOption={(input, option) =>
            (option?.label?.toString() ?? '')
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          options={options}
        />
      ) : (
        <Input
          required={required}
          disabled={disabled}
          value={value}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          ref={ref}
          type={type}
        />
      )}
    </div>
  );
};

export default InputWithMovingPlaceholder;
